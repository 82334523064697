import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import QuestionnaireTemplateEditor from './QuestionnaireEditor';
import { ProjectVariable } from '../../../../../models/DataRequestHub/ProjectVariable';
import QuestionnaireStagesList from '../../../../DataRequest/QuestionnaireStagesList/QuestionnaireStagesList';
import {
    DataRequestProject,
    DataRequestProjectState,
} from '../../../../../models/DataRequestHub/DataRequestProject';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import DataFormQuestion from '../../../../../models/DataRequestHub/DataFormQuestion';
import {
    isDateInvalid,
    removeFormListIfNeeded,
    removeFormTypeIfNeeded,
    removeTextSizeIfAnswerTypeIsNotText,
    validateRowDisplayOrder,
    validateQuestionsFormDisplayTextSize,
    validateRequiredFormList,
    validateRequiredFormType as validateRequiredFormType,
    validateSubQuestionsFormDisplayOrder,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import {
    DataForm,
    emptyDataForm,
} from '../../../../../models/DataRequestHub/DataForm';
import { DesignModeSwitcher } from '../../../../DataRequest/DesignModeSwitcher/DesignModeSwitcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { TableSettingsPopup } from '../../Popups/TableSettingsPopup/TableSettingsPopup';
import { EditorModeEnum } from '../../../../../models/DataRequestHub/ProjectEditorModeEnum';
import { EditorTypeEnum } from '../../../../../models/DataRequestHub/ProjectEditorEnum';

interface QuestionnaireTemplateEditroHostProps {
    isAdminView: boolean;
    editMode: EditorModeEnum;
    templateForms: DataForm[];
    setForms(forms: DataForm[]): void;
    isLoading: boolean;
    answerType: ProjectVariable;
    priority: ProjectVariable;
    textSize: ProjectVariable;
    formType: ProjectVariable;
    numericFormat: ProjectVariable;
    notSystemVariables: ProjectVariable[];
    updateProjectData(): void;
    updateProjectVariables(): void;
    project: DataRequestProject;
}

const QuestionnaireTemplateEditorHost = forwardRef(
    (props: QuestionnaireTemplateEditroHostProps, ref) => {
        const editorRef = useRef(null);
        const [selectedQuestionnaireId, setSelectedQuestionnaireId] =
            useState(0);
        const [invalidStageIds, setInvalidStageIds] = useState<number[]>([]);
        const [isTableSettingsPopupVisible, setIsTableSettingsPopupVisible] =
            useState(false);
        const formNameRef = useRef(null);
        const location = useLocation();
        const navigate = useNavigate();

        useEffect(() => {
            if (isTemplateEditor) {
                setSelectedQuestionnaireId(props.templateForms[0].id);
            }
        }, [props, selectedQuestionnaireId]);

        useEffect(() => {
            if (location.state.questionnaireEditorParams) {
                const preselectedQuestionnaireId =
                    location.state.questionnaireEditorParams
                        .targetQuestionnaireId;
                const isQuestionnaireExists = props.templateForms.some(
                    (s) => s.id === preselectedQuestionnaireId
                );

                isQuestionnaireExists &&
                    setSelectedQuestionnaireId(preselectedQuestionnaireId);
            }
        }, []);

        useEffect(() => {
            if (!isTemplateEditor) {
                validateProjectForms();
            }
        }, [props.templateForms]);

        const isTemplateEditor = props.editMode !== EditorModeEnum.EditProject;

        useImperativeHandle(ref, () => ({
            addRow() {
                editorRef.current.addRow();
            },
            validateAllRows() {
                let isFormValid = true;
                if (isTemplateEditor || selectedQuestionnaireId) {
                    isFormValid = editorRef.current.validateAllRows();
                }

                return validateProjectForms() && isFormValid;
            },
        }));

        const getSelectedQuestionnaireStage = () =>
            props.templateForms.find(
                (form) => form.id === selectedQuestionnaireId
            ) ?? emptyDataForm;

        const getSelectedStageRows = () => [
            ...(props.templateForms.find(
                (form) => form.id === selectedQuestionnaireId
            )?.questions ?? []),
        ];

        const updateSelectedStageRows = (rows: DataFormQuestion[]) => {
            const targetForm = props.templateForms.find(
                (form) => form.id === selectedQuestionnaireId
            );
            const targetFormIndex = props.templateForms.indexOf(targetForm);
            targetForm.questions = removeTextSizeIfAnswerTypeIsNotText(rows);
            targetForm.questions = removeFormTypeIfNeeded(
                targetForm.questions,
                EditorTypeEnum.Questionnaire
            );
            targetForm.questions = removeFormListIfNeeded(
                targetForm.questions,
                EditorTypeEnum.Questionnaire
            );

            const updatedForms = [...props.templateForms];
            updatedForms[targetFormIndex] = targetForm;
            props.setForms(updatedForms);
        };

        const validateProjectForms = () => {
            const validationResult = props.templateForms.map((form) => ({
                formId: form.id,
                areRowsValid: validateQuestionnaireFormRows(form.questions),
                isNameValid: !!form.originalTemplateName,
            }));

            const invalidStageIds = validationResult
                .filter((result) => !result.areRowsValid || !result.isNameValid)
                .map((result) => result.formId);
            setInvalidStageIds(invalidStageIds);

            return !validationResult.some(
                (s) => !s.areRowsValid || !s.isNameValid
            );
        };

        const validateQuestionnaireFormRows = (
            templateRows: DataFormQuestion[]
        ) => {
            const questions = templateRows.filter(
                (question) => !question.isSubQuestion
            );
            const subQuestions = templateRows.filter(
                (question) => question.isSubQuestion
            );

            const haveQuestionsError = questions.some((question) =>
                doesQuestionMissRequiredFields(question)
            );

            if (haveQuestionsError) {
                return false;
            }

            const haveSubQuestionsError = subQuestions.some((subQuestion) =>
                doesSubQuestionMissRequiredFields(subQuestion)
            );

            if (haveSubQuestionsError) {
                return false;
            }

            if (validateRowDisplayOrder(questions)) {
                return false;
            }

            if (validateSubQuestionsFormDisplayOrder(subQuestions)) {
                return false;
            }

            if (validateQuestionsFormDisplayTextSize(templateRows)) {
                return false;
            }

            if (validateRequiredFormType(templateRows)) {
                return false;
            }

            if (validateRequiredFormList(templateRows)) {
                return false;
            }

            return true;
        };

        const doesQuestionMissRequiredFields = (
            question: DataFormQuestion
        ): boolean =>
            !question.displayModule ||
            !question.displayQuestion ||
            !question.answerType ||
            (isDateInvalid(question.dueDate) &&
                props.editMode === EditorModeEnum.EditProject) ||
            (!question.duePeriod &&
                props.editMode !== EditorModeEnum.EditProject) ||
            !question.priority ||
            hasDisplayLogicError(question);

        const doesSubQuestionMissRequiredFields = (
            subQuestion: DataFormQuestion
        ): boolean =>
            !subQuestion.parentQuestionId ||
            !subQuestion.displayOrderSub ||
            !subQuestion.displayQuestion ||
            !subQuestion.answerType ||
            hasDisplayLogicError(subQuestion);

        const hasDisplayLogicError = (question: DataFormQuestion) => {
            const hasEmptyString = question.conditionValues?.some(
                (item) => !!!item?.data.toString().trim().length
            );

            const isArrayEmpty = !question.conditionValues?.length;

            const hasError = hasEmptyString || isArrayEmpty;
            return (
                question.isDisplayLogicEnabled &&
                (!question.logicParentCustomId || hasError)
            );
        };

        const renderStageList = () => (
            <QuestionnaireStagesList
                onStageNameClick={setSelectedQuestionnaireId}
                isAdminView={props.isAdminView}
                isEditorMode={true}
                projects={[props.project]}
                invalidStageIds={invalidStageIds}
                updateProjectsData={props.updateProjectData}
                openSettings={() => setIsTableSettingsPopupVisible(true)}
                questionsSeenStatus={[]}
            />
        );

        useLayoutEffect(() => {
            if (formNameRef?.current) {
                formNameRef.current.setFieldTouched('templateName', true, true);
            }
        }, [formNameRef.current]);

        const renderEditor = () => {
            const questionnaireStage = getSelectedQuestionnaireStage();

            return (
                <>
                    {isTemplateEditor ? (
                        <></>
                    ) : (
                        <>
                            <div className="stage-navigation-breadcrumb">
                                <label
                                    className="link-text"
                                    onClick={() =>
                                        setSelectedQuestionnaireId(null)
                                    }>
                                    Questionnaire
                                </label>
                                <label className="node-separator">
                                    {' / '}
                                </label>
                                <label className="link-text">
                                    {questionnaireStage.customName}
                                </label>
                            </div>
                            <div className="editor-header">
                                <Col>
                                    <Row className="header-row">
                                        <Label>
                                            <strong>
                                                {'Original Template Name: '}
                                            </strong>
                                            {
                                                questionnaireStage.originalTemplateName
                                            }
                                        </Label>
                                        <Button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={() => {
                                                editorRef?.current.addRow();
                                            }}>
                                            {'+ Add New Row'}
                                        </Button>
                                    </Row>
                                </Col>
                                <div className="project-settings-section">
                                    <div
                                        className="clickable"
                                        onClick={() => {
                                            setIsTableSettingsPopupVisible(
                                                true
                                            );
                                        }}>
                                        <FontAwesomeIcon icon={faWrench} />
                                        {'User View Settings'}
                                    </div>
                                    {props.project.state ===
                                        DataRequestProjectState.Active && (
                                        <DesignModeSwitcher
                                            formId={0}
                                            defaultValue={true}
                                            onDisable={() => {
                                                navigate(
                                                    `/data-request-dashboard?tab=${
                                                        EditorTypeEnum.Questionnaire +
                                                        1
                                                    }`,
                                                    {
                                                        state: {
                                                            preselectedQuestionnaireFormId:
                                                                selectedQuestionnaireId,
                                                            projectId:
                                                                props.project
                                                                    .id,
                                                            isAdmin: true,
                                                            projectName:
                                                                props.project
                                                                    .name,
                                                            practiceName:
                                                                props.project
                                                                    .practiceName,
                                                        },
                                                    }
                                                );
                                            }}></DesignModeSwitcher>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    <QuestionnaireTemplateEditor
                        isProjectEditMode={
                            props.editMode === EditorModeEnum.EditProject
                        }
                        templateRows={getSelectedStageRows()}
                        setTemplateRows={updateSelectedStageRows}
                        isLoading={props.isLoading}
                        answerType={props.answerType}
                        priority={props.priority}
                        textSize={props.textSize}
                        formType={props.formType}
                        numericFormat={props.numericFormat}
                        notSystemVariables={props.notSystemVariables}
                        ref={editorRef}
                    />
                </>
            );
        };

        return (
            <>
                {isTemplateEditor
                    ? renderEditor()
                    : selectedQuestionnaireId
                    ? renderEditor()
                    : renderStageList()}

                {isTableSettingsPopupVisible && (
                    <TableSettingsPopup
                        isVisible={isTableSettingsPopupVisible}
                        setIsVisible={setIsTableSettingsPopupVisible}
                        onSubmit={() => {
                            props.updateProjectData();
                            props.updateProjectVariables();
                        }}
                        projectId={props.project.id}
                        dataTables={props.project.dataTables}
                        dataForms={
                            props.project.dataForms
                        }></TableSettingsPopup>
                )}
            </>
        );
    }
);
export default QuestionnaireTemplateEditorHost;
